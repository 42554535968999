// Deep Link offer
.SingleOffer {
  max-width: 25%;
  margin: 0 auto;
}

// List of offers
.OfferList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  grid-template-rows: auto;
  grid-gap: 10px;
  justify-content: center;
}
// offer item
.offer {
  position:relative;
  max-width: 250px;
  .content {
    font-size: 0.8em;
    padding:1rem 1rem .5rem;
  }
  .offer-img {
    object-fit: none;
    width: auto;
    padding: 1em 1em 0;
  }
  .card-title,.card-text{
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    padding: 0;
  }
  .card-title{
    -webkit-line-clamp: 2;
    font-weight: 400;
    margin-bottom: 0.5em;
    max-height: 2.4em;
    min-height: 2.4em;
    line-height: 1.1em;
  }
  .description{
    -webkit-line-clamp: 2;
    font-size: 1.1em;
    font-weight: 400;
    margin:0;
    max-height:2.3em; //IE11 fix includes font size and line height 
    min-height: 2.3em;
    line-height: 1.2em;
  }
  .expiration{
    font-size:.9em
  }
  .offer-btn{
    width: 80%;
    margin: 0 auto 1rem;
    border-radius: 7px;
  }
}
@media screen and(max-width:440px){
  .offer{
    .card-title{
      -webkit-line-clamp: initial;
      min-height:initial;
    }
  }
}