@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.register-bar{padding:1em;margin-bottom:1em}

.moreInfo,.overlay{position:absolute;top:0;left:0;width:100%;height:100%}.moreInfo .content,.overlay .content{color:#fff;font-size:1.4rem;font-weight:700;padding:1rem;position:relative;height:100%;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.moreInfo .content .close,.overlay .content .close{position:absolute;top:0.35em;right:0.35em;color:#fff}.disable-link{pointer-events:none}

.SingleOffer{max-width:25%;margin:0 auto}.OfferList{display:grid;grid-template-columns:repeat(auto-fit, minmax(200px, auto));grid-template-rows:auto;grid-gap:10px;-webkit-justify-content:center;justify-content:center}.offer{position:relative;max-width:250px}.offer .content{font-size:0.8em;padding:1rem 1rem .5rem}.offer .offer-img{object-fit:none;width:auto;padding:1em 1em 0}.offer .card-title,.offer .card-text{display:-webkit-box;overflow:hidden;text-overflow:ellipsis;-webkit-box-orient:vertical;padding:0}.offer .card-title{-webkit-line-clamp:2;font-weight:400;margin-bottom:0.5em;max-height:2.4em;min-height:2.4em;line-height:1.1em}.offer .description{-webkit-line-clamp:2;font-size:1.1em;font-weight:400;margin:0;max-height:2.3em;min-height:2.3em;line-height:1.2em}.offer .expiration{font-size:.9em}.offer .offer-btn{width:80%;margin:0 auto 1rem;border-radius:7px}@media screen and (max-width: 440px){.offer .card-title{-webkit-line-clamp:initial;min-height:0;min-height:initial}}

.heroImg{width:100%}.gardenImg{max-width:100%;image-rendering:-webkit-optimize-contrast}@media screen and (max-width: 675px){.gardenImg{width:100%}}

.content-page img.hero{width:100%}.content-page .hashText{margin:2.5em auto 1em}.content-page .content{display:grid;grid-template-columns:repeat(auto-fit, minmax(300px, auto));grid-template-rows:auto;grid-gap:0;-webkit-justify-content:center;justify-content:center}.content-page .content .content-item{position:relative;border-radius:0;border:0;background:#2065c3;overflow:hidden}.content-page .content .content-item a{text-decoration:none;color:#585858}.content-page .content .content-item a .card-img-top{height:20em;object-fit:cover;object-position:bottom}.content-page .content .content-item .card-body{display:block;padding:0;position:absolute;bottom:0;background:#fff;width:100%;transition:-webkit-transform 0.25s ease-out;transition:transform 0.25s ease-out;transition:transform 0.25s ease-out, -webkit-transform 0.25s ease-out;-webkit-transform:translateY(100%);transform:translateY(100%)}.content-page .content .content-item .card-body .card-title{color:#000;text-decoration:none;margin:0;font-size:16px;font-weight:600}.content-page .content .content-item .card-body .card-text{float:left;width:100%;padding:2.5px;background-color:#f1f1f1;border-top:1px solid #ccc}.content-page .content .content-item .card-body .btn-social{display:table;float:left;width:calc((100% / 3) - 4%);margin:2%;height:30px;padding:2px;color:#585858;text-align:center;text-decoration:none;background:#fff;border:1px solid #ccc;border-radius:6px}.content-page .content .content-item:hover .card-body{-webkit-transform:translateY(0);transform:translateY(0)}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
button {
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
button:not(:disabled):not(.disabled) {
  cursor: pointer;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

